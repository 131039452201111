<template>
  <div class="pre-final-page animate__animated animate__fadeIn pt-3">

    <div class="text-center mb-5">
      <img :src="`${urlBucket}/pre-form/sloth.png`" class="img-fluid" style="max-width: 285px">
    </div>

    <div class="text-center my-4 mt-5">
      <span class="step"></span>
    </div>

    <div class="d-flex justify-content-center mb-5">
      <div class="text-center" style="max-width: 370px">
        <h3 class="fw-bold mb-4" style="font-size: 24px;">You’re almost there 🤞</h3>
        <p style="font-size: 18px;">
          Next, we’ll review your address updates and complete your SlothMove. Then, you will get access to your dashboard.
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { navigateTo } from "../router/index.js";

export default {
  name: "PreFinalPage",
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.$store.commit("setField", ["titleNavbar", `${this.firstName}'s move`]);

    this.$store.commit("setField", ["messageButton", "REVIEW"]);

    const validatePage = () => {
      let route = "/review";

      navigateTo(route);
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
        this.$router.back();
      }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },

  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "referrer",
      "firstName",
      "baseURL",
      "urlBucket",
      "informLoyalty",
      "informStudentFinance",
      "informNHS",
      "informDVLA",
      "informBanks",
      "informElectoralRoll",
      "informHMRC",
      "informMobile",
      "informDentist",
      "informBonds",
      "informGyms",
      "informDWP",
      "informPension",
      "informCharities",
      "informLottery",
      "informEnergy",
      "informEnergy",
      "informTVLicense",
      "informNewCouncil",
      "informNewWater",
      "informOldCouncil",
      "informOldWater",
      //Selections - arrays
      "pensionSelection",
      "mobileSelection",
      "selectedBanks",
      "selectedGyms",
      "selectedCharities",
      "selectedLoyalties",
      //Money Time saved
      "timeSaved",
      "moneySaved",
    ]),
  },
  watch: {
    timeSaved(val) {
      this.$store.commit("formStore/setField", [`timeSaved`, val]);
    },
  },
};
</script>
<style lang="scss">
.pre-final-page {
  .step {
    display: inline-block;
    width: 60px;
    height: 12px;
    border-radius: 20px;
    background-color: white;
  }
}
</style>
